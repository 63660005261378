import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MonacoEditor from 'react-monaco-editor';
import Box from '../box/box.jsx';
import styles from './code-editor.css';
import cameraIcon from './icon--camera.svg';
import copyIcon from './icon--copy.svg';

const CodeEditorComponent = props => {
    const {
        containerRef,
        language,
        value,
        options,
        width,
        height,
        onChange,
        editorDidMount,
        theme,
        ...componentProps
    } = props;

    // State để quản lý camera và IP
    const [showCamera, setShowCamera] = useState(false);
    const [ipCamera, setIpCamera] = useState('');
    const [connected, setConnected] = useState(false);

    // Hàm xử lý khi nhấn nút Camera
    const toggleCamera = () => {
        // Nếu showCamera chuyển về false, reset lại kết nối
        if (showCamera) {
            setConnected(false);  // Ẩn camera, quay lại nhập IP
        }
        setShowCamera(prevState => !prevState);
    };

    // Hàm xử lý khi người dùng nhấn nút Connect
    const handleConnect = () => {
        if (ipCamera) {
            setConnected(true);
            setShowCamera(true);
        }else if(showCamera === false){
            setShowCamera(false);
            setConnected(false);
        }
        else{
            setShowCamera(false);
            setConnected(false);
        }
    };


    return (
        <Box
            className={styles.codeEditor}
            componentRef={containerRef}
        >
            <div className={styles.cameraGroup} style={{ position: 'absolute', zIndex: 1 , marginLeft: '90px'}}>
                <div className={styles.cameraButton} onClick={toggleCamera}>
                    <img
                        
                        className={styles.cameraIcon}
                        draggable={false}
                        src={cameraIcon}
                    />
                    <p className={styles.titleCamera}>{showCamera ? 'Hide Camera' : 'Camera'}</p>
                </div>
            </div>

            <div className={styles.copyGroup} style={{ position: 'absolute', zIndex: 1 ,display: showCamera ? 'none' : 'flex', marginLeft: '215px'}}>
                <div className={styles.copyButton} onClick={() => navigator.clipboard.writeText(value)}>
                    <img
                        
                        className={styles.copyIcon}
                        draggable={false}
                        src={copyIcon}
                    />
                    <p className={styles.titleCopy}>Copy Code</p>
            </div>
            </div>

            {/* <button 
                style={{ height: '25px', width: '100px', position: 'absolute', right: '30px', zIndex: 1 }} 
                onClick={() => navigator.clipboard.writeText(value)}
            >
                Copy code
            </button> */}

            {showCamera ? (
                connected ? (
                    // Hiển thị video nếu đã kết nối IP camera
                    <div className="container">
                        <img
                            src={`http://${ipCamera}`} 
                            alt="Live Camera Connect Failed"
                            style={{ width: 480, height: 330 }}
                        />
                    </div>
                ) : (
                    // Form nhập địa chỉ IP trước khi kết nối
                    <div className={styles.ipCamera} style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
                        <label htmlFor="ipCamera" style={{width:'200px',marginLeft:'3px'}}>IP Camera Address:</label>
                        <div style={{padding:'10px', marginLeft:'0px'}}>
                        <input 
                            style={{width:'200px',padding: '10px',borderRadius: "8px",border: "1px solid #ccc"}}
                            type="text"
                            name="ipCamera"
                            value={ipCamera}
                            onChange={e => setIpCamera(e.target.value)}
                        />
                        </div>
                        <input
                            style={{width:'100px',height:'30px' ,borderRadius: "5px",border: "1px solid",backgroundColor:'#40BF4A', color:'white'}}
                            type="button"
                            value="Connect"
                            onClick={handleConnect}
                        />
                    </div>
                )
            ) : (
                <MonacoEditor
                    language={language}
                    value={value}
                    options={options}
                    width={width}
                    height={height}
                    onChange={onChange}
                    editorDidMount={editorDidMount}
                    theme={theme}
                    {...componentProps}
                />
            )}
        </Box>
    );
};

CodeEditorComponent.propTypes = {
    containerRef: PropTypes.func,
    language: PropTypes.string,
    value: PropTypes.string,
    options: PropTypes.shape({
        highlightActiveIndentGuide: PropTypes.bool,
        cursorSmoothCaretAnimation: PropTypes.bool,
        readOnly: PropTypes.bool,
        contextmenu: PropTypes.bool,
        minimap: PropTypes.shape({
            enabled: PropTypes.bool
        })
    }),
    height: PropTypes.number,
    width: PropTypes.number.isRequired,
    onChange: PropTypes.func,
    editorDidMount: PropTypes.func,
    theme: PropTypes.string
};

CodeEditorComponent.defaultProps = {
    language: 'cpp',
    theme: 'vs-light',
    options: {
        highlightActiveIndentGuide: false,
        cursorSmoothCaretAnimation: true,
        readOnly: true,
        contextmenu: false,
        minimap: {
            enabled: false
        }
    }
};

export default CodeEditorComponent;
